export const useTheme = () => {
  const route = useRoute();

  const availableThemes = ["vendapp", "claro", "vemqda"];
  const config = useAppConfig();
  const runtime = useRuntimeConfig();

  const DEFAULT_THEME = computed(() => {
    const { URL_API } = runtime.public;

    if (URL_API.includes("vendapp")) {
      return "vendapp";
    }

    if (URL_API.includes("vemqda")) {
      return "vemqda";
    }

    return "vendapp";
  });

  const theme = computed(() => {
    if (availableThemes.includes(route?.params?.theme as string)) {
      return route.params.theme as string;
    }

    return DEFAULT_THEME.value;
  });

  const isVendapp = computed(() => {
    return theme.value === "vendapp";
  });

  const isVemqda = computed(() => {
    return theme.value === "vemqda";
  });

  onMounted(() => {
    config.ui.primary = theme.value;
  });

  return {
    theme,
    isVemqda,
    isVendapp,
  };
};
